<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜素框 -->
			<el-form :inline="true" :model="params" class="form" size="small">
				<el-form-item label="群组名称">
					<el-input v-model="params.name" placeholder="请输入群组名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="checkData" type="primary" size="mini"> 查询 </el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 表格 -->
			<div class="grid">
				<el-button type="primary" size="small" class="btn-right" @click="add()"> 新增 </el-button>
				<el-table
					:data="tableData"
					stripe
					style="padding: 0 20px"
					height="80%"
					size="small"
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
				>
					<el-table-column prop="name" sortable label="群组名称"></el-table-column>
					<el-table-column prop="leaderName" sortable label="负责人名称"></el-table-column>
					<el-table-column prop="sort" sortable label="排序"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-tooltip effect="dark" content="管理人员" placement="top-end">
								<el-button
									type="success"
									icon="el-icon-monitor"
									circle
									size="mini"
									@click="openUserView(scope.row)"
									v-show="authorization('/home-admin/routineManage/group-manage/edit')"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="编辑" placement="top-end">
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="edit(scope.row)"
									v-show="authorization('/home-admin/routineManage/group-manage/edit')"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="删除" placement="top-end">
								<el-popconfirm title="确认删除吗？" @confirm="deleteRegion(scope.row)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										v-show="authorization('/home-admin/routineManage/group-manage/del')"
										circle
										size="mini"
										style="margin-left: 10px"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div class="pager">
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
		</div>
		<!-- 弹出层 -->
		<el-dialog
			title="新增"
			:visible.sync="dialogFormAdd"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="groupArray"
				:rules="rules"
				ref="groupArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="群组名称" prop="name">
					<el-input v-model="groupArray.name" maxlength="255"></el-input>
				</el-form-item>
				<el-form-item label="负责人" prop="leaderObj">
					<el-select
						style="width: 100%"
						filterable
						v-model="groupArray.leaderId"
						placeholder="请选择"
						@change="leaderChange"
					>
						<el-option
							v-for="item in allUserList"
							:key="item.id"
							:label="item.userName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input-number v-model="groupArray.sort" :min="0"> </el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="addSubMit('groupArray')" size="mini"> 确 定 </el-button>
			</div>
		</el-dialog>
		<!-- 编辑弹出层 -->
		<el-dialog
			title="编辑"
			:visible.sync="dialogFormEdit"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="groupArray"
				:rules="rules"
				ref="groupArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="群组名称" prop="name">
					<el-input v-model="groupArray.name"></el-input>
				</el-form-item>
				<el-form-item label="负责人" prop="leaderObj">
					<el-select
						style="width: 100%"
						filterable
						v-model="groupArray.leaderId"
						placeholder="请选择"
						@change="leaderChange"
					>
						<el-option
							v-for="item in allUserList"
							:key="item.id"
							:label="item.userName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input-number v-model="groupArray.sort" :min="0"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="editSubMit('groupArray')" size="mini"> 确 定 </el-button>
			</div>
		</el-dialog>
		<!-- 人员管理弹出层 -->
		<el-dialog
			title="群组人员"
			:visible.sync="dialogFormGroupUser"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="groupUserArray"
				:rules="groupUserRules"
				ref="groupUserArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="群组名称" prop="name">
					{{ groupUserArray.name }}
				</el-form-item>
				<el-form-item label="负责人" prop="leaderObj">
					{{ groupUserArray.leaderName }}
				</el-form-item>
				<el-form-item label="群组人员" prop="userList">
					<el-select
						filterable
						v-model="groupUserArray.userIdList"
						multiple
						style="width: 100%"
						placeholder="请选择"
					>
						<el-option
							v-for="item in userList"
							:key="item.id"
							:label="item.userName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormGroupUser = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="addGroupSubMit('groupUserArray')" size="mini">
					确 定
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import moment from 'moment';
	import { authorization } from '../../../authorization/authorization';
	export default {
		data() {
			return {
				dialogFormAdd: false,
				dialogFormEdit: false,
				dialogFormGroupUser: false,
				tableData: [],
				allUserList: [],
				userList: [],
				params: {
					name: '',
					page: 1,
					size: 10,
				},
				groupUserParams: {
					userId: null,
					groupId: null,
				},
				groupArray: {
					id: '',
					name: '',
					leaderName: '',
					leaderId: 0,
					sort: 1,
				},
				groupUserArray: {
					groupId: '',
					name: '',
					leaderName: '',
					userIdList: [],
				},
				rules: {
					name: [
						{ required: true, message: '请输入群组名称', trigger: 'blur' },
						{ max: 255, message: '最大长度为255个字', trigger: 'blur' },
					],
					sort: [{ required: true, message: '请填写排序编号', trigger: 'blur' }],
				},
				groupUserRules: {
					userIdList: [{ required: true, message: '请选择群组人员', trigger: 'blur' }],
				},
				total: 0,
				sizes: [10, 15],
			};
		},
		created() {
			this.queryAllUserList();
		},
		methods: {
			authorization,
			queryGroupList() {
				//获取区域列表
				this.axios({
					method: 'get',
					url: '/v1/web/group-manage/select-group-list',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			queryAllUserList() {
				//所属师傅下拉（包含暂不接单的师傅）
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
				}).then((res) => {
					let list = res.data.data.list;
					let newList = [];
					for (let i = 0; i < list.length; i++) {
						list[i].userName = list[i].userName.substring(0, list[i].userName.indexOf('('));
						newList.push(list[i]);
					}
					this.allUserList = newList;
					this.queryGroupList();
				});
			},
			queryUserList(userId) {
				this.userList = [];
				for (let i = 0; i < this.allUserList.length; i++) {
					if (this.allUserList[i].id == userId) {
						continue;
					}
					this.userList.push(this.allUserList[i]);
				}
			},
			leaderChange(item) {
				this.groupArray.leaderId = item;
				var index = this.allUserList.findIndex((p) => p.id == item);
				this.groupArray.leaderName = this.allUserList[index].userName;
			},
			add() {
				//新增弹出层
				this.dialogFormAdd = true;
				this.groupArray = {};
			},
			addSubMit(formName) {
				//新增提交
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/group-manage/add-group',
							data: this.groupArray,
						}).then((res) => {
							if (res.data.code === 200) {
								console.log(res);
								this.dialogFormAdd = false;
								this.$message({
									message: '添加成功',
									type: 'success',
								});
								this.queryGroupList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			edit(row) {
				//编辑弹出层
				this.dialogFormEdit = true;
				this.groupArray = { ...row };
			},
			editSubMit(formName) {
				//编辑提交
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/group-manage/update-group/' + this.groupArray.id,
							data: this.groupArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormEdit = false;
								this.$message({
									message: '修改成功',
									type: 'success',
								});
								this.queryGroupList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			addGroupSubMit(formName) {
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/group-manage/update-group/' + this.groupUserArray.id,
							data: this.groupUserArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormGroupUser = false;
								this.$message({
									message: '修改成功',
									type: 'success',
								});
								this.queryGroupList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			deleteRegion(row) {
				//删除提交
				this.axios({
					method: 'delete',
					url: '/v1/web/group-manage/delete-group/' + row.id,
				}).then((res) => {
					if (res.data.code === 200) {
						this.queryGroupList();
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						});
					}
				});
			},
			openUserView(row) {
				this.groupUserArray = { ...row };
				this.groupUserArray.userIdList = [];
				if (row.userIdList != null) {
					for (let i = 0; i < row.userIdList.length; i++) {
						if (row.userIdList[i] === this.groupUserArray.leaderId) {
							continue;
						}
						this.groupUserArray.userIdList.push(row.userIdList[i]);
					}
				}

				this.queryUserList(row.leaderId);
				this.dialogFormGroupUser = true;
			},
			dateFormat: function (row) {
				if (row.createTime !== null && row.createTime !== undefined) {
					return moment(row.createTime).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryGroupList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryGroupList();
			},
			checkData() {
				this.queryGroupList();
			},
			reset() {
				this.params = { page: 1, size: 10 };
				this.queryGroupList();
			},
		},
	};
</script>

<style slot-scope>
	.grid {
		height: 82%;
		background-color: #fff;
		border-radius: 8px;
		padding-top: 8px;
	}
	.btn-right {
		float: right;
		margin-top: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
	}
	.form {
		padding-top: 20px;
		padding-left: 20px;
		margin-bottom: 20px;
		background-color: #fff;
		border-radius: 8px;
	}
	.pager {
		padding-top: 20px;
		height: 40px;
		background-color: #fff;
		width: 100%;
		border-radius: 8px;
		text-align: right;
	}
</style>
